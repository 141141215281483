import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';


const StyledButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    
    width: 200px;
    height: 60px;
    
    font-size: 32px;
    
    @media (max-width: ${props => props.theme.bps.md}) {
      width: 120px;
      height: 40px;
      
      font-size: 16px;
    }
    
    border-radius: 8px;
    
    background: ${props => props.theme.primary};
    color: ${props => props.theme.white};
    
    &:hover {
      background: ${props => props.theme.primaryHover};
    }
    cursor: pointer;
`;


const Button = ({ children, onClick }) => (
    <StyledButton
        onClick={onClick}
    >
        <span>{children}</span>
    </StyledButton>
);

Button.propTypes = {
    children: PropTypes.string,
    onClick: PropTypes.func,
};

Button.defaultProps = {
    children: '',
    onClick: () => {},
};

export default Button;
