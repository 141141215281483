import seedrandom from 'seedrandom';


const weightedChoice = ({ choices, weights, r }) => {
    if (r === undefined) r = Math.random;

    if (weights.reduce((a, x) => a + x, 0) !== 1.0) throw new Error('`weights` must sum to 100%');

    const x = r();
    let cum = 0;

    for (let i=0; i < choices.length; i++) {
        cum += weights[i];

        if (x < cum) return choices[i];
    }
};


export const addRandomMarkdown = (text) => {
    const r = seedrandom(text);

    const formats = [
        {start: '*', end: '*'},
        {start: '**', end: '**'},
        {start: '[', end: '](https://example.com)'},
    ];

    let transformedText = '';

    const paras = text.split('\n');

    for (let para of paras) {
        let firstWord = true;

        const words = para.split(' ');

        let current = null;
        for (let word of words) {
            if (word === '') continue;

            if (current) {
                if (r() < 0.5) {
                    transformedText += current.end;

                    current = null;
                }

                transformedText += ' ';
                transformedText += word;
            } else {
                if (!firstWord) {
                    transformedText += ' ';
                } else {
                    firstWord = false;
                }

                if (r() < 0.1) {
                    current = weightedChoice({
                        choices: formats,
                        weights: [0.4, 0.4, 0.2],
                        r,
                    });

                    transformedText += current.start;
                }

                transformedText += word;
            }
        }

        if (current) {
            transformedText += current.end;
        }

        transformedText += '\n';
    }

    // remove trailing newline
    transformedText = transformedText.slice(0, -1);

    return transformedText;
};

export const addRandomHtml = (text) => {
    const r = seedrandom(text);

    const formats = [
        {start: '<em>', end: '</em>'},
        {start: '<strong>', end: '</strong>'},
        {start: '<a href="https://example.com">', end: '</a>'},
    ];

    let transformedText = '';

    const paras = text.split('\n');

    for (let para of paras) {
        let firstWord = true;

        if (para !== '') {
            transformedText += '<p>';
        } else {
            transformedText += '<br/>';
            continue;
        }

        const words = para.split(' ');

        let current = null;
        for (let word of words) {
            if (word === '') continue;

            if (current) {
                if (r() < 0.5) {
                    transformedText += current.end;

                    current = null;
                }

                transformedText += ' ';
                transformedText += word;
            } else {
                if (!firstWord) {
                    transformedText += ' ';
                } else {
                    firstWord = false;
                }

                if (r() < 0.1) {
                    current = weightedChoice({
                        choices: formats,
                        weights: [0.4, 0.4, 0.2],
                        r,
                    });

                    transformedText += current.start;
                }

                transformedText += word;
            }
        }

        if (current) {
            transformedText += current.end;
        }

        if (para !== '') transformedText += '</p>';
    }

    return transformedText;
};
