import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';


const StyledNumberInput = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  
  & input {
    appearance: none;
    border: none;
    
    &::-webkit-inner-spin-button {
      appearance: none;
    }
    
    &:focus {
      outline: none;
    }
    
    width: 80vw;
    
    text-align: center;
    
    font-size: 92px;
    
    @media (max-width: ${props => props.theme.bps.md}) {
      font-size: 80px;
    }
  }
  
  margin-bottom: 20px;
  
  @media (max-width: ${props => props.theme.bps.md}) {
    margin-bottom: 10px;
  }
`;


const NumberInput = ({ children: n, onChange }) => (
    <StyledNumberInput>
        <input
            type="number"
            value={n || ''}
            onChange={e => {
                const newN = parseInt(e.target.value);

                if (isNaN(newN)) {
                    onChange(null);
                } else {
                    onChange(newN);
                }
            }}
        />
    </StyledNumberInput>
);

NumberInput.propTypes = {
    children: PropTypes.number,
};

NumberInput.defaultProps = {};

export default NumberInput;
