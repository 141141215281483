import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';


const StyledChoice = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    
    font-size: 32px;
    
    width: 180px;
    
    @media (max-width: ${props => props.theme.bps.md}) {
      font-size: 16px;
      
      width: 100px;
    }
    
    color: ${props => props.selected ? props.theme.primary : props.theme.fadeGrey};
    
    &:hover {
      color: ${props => props.selected ? props.theme.primary : props.theme.primaryHover};
    }
    
    cursor: pointer;
`;


const Choice = ({ children, selected, onClick }) => (
    <StyledChoice
        selected={selected}
        onClick={onClick}
    >
        <span>{children}</span>
    </StyledChoice>
);

Choice.propTypes = {
    children: PropTypes.string,
    selected: PropTypes.bool,
    onClick: PropTypes.func,
};

Choice.defaultProps = {
    children: '',
    selected: false,
    onClick: () => {},
};

export default Choice;
