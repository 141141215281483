import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import ButtonGroup from "../ButtonGroup";
import Button from "../Button";
import Choices from "../Choices";
import NumberInput from "../NumberInput";


const StyledLandingPage = styled.div``;

const LeadText = styled.div`
  margin-top: 64px;
  margin-bottom: 40px;
  
  font-size: 48px;
  font-weight: 500;
  text-align: center;
  
  @media (max-width: ${props => props.theme.bps.md}) {
    margin-top: 32px;
    margin-bottom: 20px;
    
    font-size: 24px;
  }
  
  color: ${props => props.theme.black};
`;
const SubText = styled.div`
  margin-bottom: 40px;
  
  font-size: 36px;
  font-weight: 300;
  text-align: center;
  
  @media (max-width: ${props => props.theme.bps.md}) {
    margin-bottom: 20px;
    
    font-size: 18px;
  }
  
  color: ${props => props.theme.black};
`;



const LandingPage = ({ n, onChangeN, units, unitChoices, onChangeUnits, generate }) => (
    <StyledLandingPage>
        <LeadText>Lorem ipsum for crypto.</LeadText>
        <SubText>How much placehodler do you need?</SubText>

        <NumberInput
            onChange={onChangeN}
        >
            {n}
        </NumberInput>
        <Choices
            choices={unitChoices}
            chosen={units}
            onChoose={onChangeUnits}
        />

        <ButtonGroup>
            <Button onClick={generate}>
                Generate
            </Button>
        </ButtonGroup>
    </StyledLandingPage>
);

LandingPage.propTypes = {
    n: PropTypes.number,
    onChangeN: PropTypes.func,

    units: PropTypes.string.isRequired,
    unitChoices: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChangeUnits: PropTypes.func,

    generate: PropTypes.func,
};

LandingPage.defaultProps = {
    n: null,

    onChangeN: () => {},
    onChangeUnits: () => {},

    generate: () => {},
};

export default LandingPage;
