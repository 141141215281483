import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';


const StyledButtonGroup = styled.div`
    display: flex;
    
    justify-content: center;
    
    width: 100%;
    
    & > div:not(:last-child) {
      margin-right: 20px;
    }
`;


const ButtonGroup = ({ children }) => (
    <StyledButtonGroup>
        {children}
    </StyledButtonGroup>
);

ButtonGroup.propTypes = {
    children: PropTypes.any,
};

ButtonGroup.defaultProps = {
    children: null,
};

export default ButtonGroup;
