import React from 'react';

import styled from 'styled-components';


const StyledShareButtons = styled.div`
  margin-top: 80px;
  
  @media (max-width: ${props => props.theme.bps.md}) {
    margin-top: 46px;
  }
`;


const ShareButtons = () => (
    <StyledShareButtons>
        <div className="sharethis-inline-share-buttons" />
    </StyledShareButtons>
);

ShareButtons.propTypes = {};

ShareButtons.defaultProps = {};

export default ShareButtons;
