import React, { Component } from 'react';
import './App.css';

import * as ReactGA from 'react-ga';
import styled, { ThemeProvider } from 'styled-components';
import LandingPage from "./components/LandingPage";
import IpsumPage from "./components/IpsumPage";
import ShareButtons from "./components/ShareButtons";
import generateIpsum from "./util/generateIpsum";

import logo from './images/logo.png';


const theme = {
    primary: '#15BC9D',
    primaryHover: '#8ADDCE',
    white: '#ffffff',
    displayGrey: '#8D8D8D',
    fadeGrey: '#D8D8D8',
    black: '#000000',

    bps: {
        md: '768px',
    },
};


const AppContainer = styled.div`
  max-width: 960px;
  margin: auto;
  
  padding: 80px;
  
  @media (max-width: ${props => props.theme.bps.md}) {
    padding: 25px;
  }
`;

const Logo = styled.div`
  display: flex;
  justify-content: center;
  
  & > img {
      width: 260px;
      height: 46px;
      
      @media (max-width: ${props => props.theme.bps.md}) {
        width: 160px;
        height: 28px;
      }
  }
`;

const Footnotes = styled.div`
  margin-top: 40px;
  
  @media (max-width: ${props => props.theme.bps.md}) {
    margin-top: 32px;
  }
  
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Footnote = styled.div`
  font-size: 18px;
  
  @media (max-width: ${props => props.theme.bps.md}) {
    font-size: 12px;
  }
  
  color: ${props => props.theme.displayGrey};
  
  & a {
    text-decoration: none;
  }
`;
const GreenLink = styled.a`
  color: ${props => props.theme.primary};
`;
const BoldLink = styled.a`
  font-weight: 500;
  
  color: ${props => props.theme.displayGrey};
`;


class App extends Component {
    unitChoices = [
        'words',
        'paragraphs',
    ];

    modeChoices = [
        'plain text',
        'markdown',
        'html',
    ];

    maxN = {
        words: 65535,
        paragraphs: 255,
    };

    state = {
        n: {
            words: 512,
            paragraphs: 4,
        },
        units: this.unitChoices[0],
        mode: this.modeChoices[0],

        generatedText: null,
    };

    regenerateText = () => {
        const { n, units } = this.state;

        this.setState({
            generatedText: generateIpsum({
                num: n[units],
                unit: units,
            }),
        });
    };

    componentDidMount() {
        if (process.env.REACT_APP_GA_UA_ID) {
            console.log('intialising GA');
            ReactGA.initialize(process.env.REACT_APP_GA_UA_ID);

            ReactGA.set({ page: window.location.pathname });
            ReactGA.pageview(window.location.pathname);
        }
    }

    render() {
        const { n, units, generatedText, mode } = this.state;

        return (
            <ThemeProvider theme={theme}>
                <AppContainer>
                    <Logo>
                        <img src={logo} alt="placehodler" />
                    </Logo>

                    {!generatedText ?
                        <LandingPage
                            n={n[units]}
                            onChangeN={(n) => {
                                this.setState((prevState) => ({
                                    n: {
                                        ...prevState.n,
                                        [units]: Math.min(n, this.maxN[units]),
                                    },
                                }));
                            }}
                            units={units}
                            unitChoices={this.unitChoices}
                            onChangeUnits={(units) => {
                                this.setState({
                                    units,
                                });
                            }}
                            generate={() => {
                                if (n[units] && !isNaN(n[units])) {
                                    ReactGA.event({
                                        category: 'Interaction',
                                        action: 'Generated text',
                                        label: `${n[units]} ${units}`,
                                    });

                                    this.regenerateText();
                                }
                            }}
                        />
                        :
                        <IpsumPage
                            rawText={generatedText}
                            regenerate={() => {
                                ReactGA.event({
                                    category: 'Interaction',
                                    action: 'Regenerated text',
                                    label: `${n[units]} ${units}`,
                                });

                                this.regenerateText();
                            }}
                            mode={mode}
                            modeChoices={this.modeChoices}
                            onChangeMode={(mode) => {
                                this.setState({
                                    mode,
                                });
                            }}
                            onMakeMore={() => {
                                this.setState({
                                    generatedText: null,
                                });
                            }}
                        />
                    }

                    <ShareButtons />

                    <Footnotes>
                        <Footnote>
                            Made by <GreenLink href="https://shapelabs.co" target="_blank">shapelabs</GreenLink>
                        </Footnote>
                        <Footnote>
                            <BoldLink href="https://medium.com/shapelabs/introducing-placehodler-1a33e8c85616" target="_blank">Read more</BoldLink> about this on Medium
                        </Footnote>
                    </Footnotes>
                </AppContainer>
            </ThemeProvider>
        );
    }
}

export default App;
