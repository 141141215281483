import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import QueueAnim from "rc-queue-anim";

import ButtonGroup from "../ButtonGroup";
import Button from "../Button";
import Choices from "../Choices";
import RefreshSvg from "../../images/RefreshSvg";


const StyledIpsumPage = styled.div``;

const RegenerateButton = styled.div`
  margin: 66px auto 40px auto;
  
  width: 80px;
  height: 80px;
  
  @media (max-width: ${props => props.theme.bps.md}) {
    margin: 20px auto 16px auto;
    
    width: 40px;
    height: 40px;
  }
  
  cursor: pointer;
  
  transition: transform 200ms ease-in-out;
  &:hover {
    transform: rotate(30deg);
  }
  
  svg {
    fill: ${props => props.theme.primary};
  }
  &:hover svg {
    fill: ${props => props.theme.primaryHover};
  }
`;
const Text = styled.div`
  max-width: 800px;
  
  font-size: 24px;
  line-height: 28px;
  
  color: ${props => props.theme.displayGrey};
  
  margin-bottom: 60px;
  
  @media (max-width: ${props => props.theme.bps.md}) {
    font-size: 14px;
    line-height: 16px;
    
    margin-bottom: 20px;
  }
`;

const ButtonWithTooltip = styled.div`
  position: relative;
`;

const Tooltip = styled.div`
  position: absolute;
  
  opacity: ${props => props.show ? 1 : 0};
  
  width: 100%;
  height: 43px;
  margin-top: calc(-43px - 6px);
  
  color: ${props => props.theme.white};
  background: ${props => props.theme.black};
  
  display: flex;
  justify-content: center;
  align-items: center;
  
  border-radius: 10px;
  padding: 8px 21px;
  
  pointer-events: none;
  
  transition: opacity 0.3s ease-out;
  
  &:before, &:after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
  }
  
  &:before {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    bottom: -8px;
    left: 50%;
    margin-left: -10px;
  }
  
  &:after {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    bottom: -6px;
    left: 50%;
    margin-left: -8px;
    
    border-top-color: ${props => props.theme.black};
    border-top-style: solid;
    border-top-width: 6px;
  }
`;


const IpsumPage = ({ children: text, regenerate, mode, modeChoices, onChangeMode, onCopyText, copied, onMakeMore }) => (
    <StyledIpsumPage>
        <RegenerateButton
            onClick={regenerate}
        >
            <RefreshSvg />
        </RegenerateButton>

        <Text>
            <QueueAnim
                animConfig={{
                    opacity: [1, 0],
                    translateY: [0, -40],
                }}
            >
                {text.split('\n').map((p, i) =>
                    <p key={i}>{p}</p>
                )}
            </QueueAnim>
        </Text>

        <Choices
            choices={modeChoices}
            chosen={mode}
            onChoose={onChangeMode}
        />

        <ButtonGroup>
            <ButtonWithTooltip>
                <Tooltip show={copied}>Copied!</Tooltip>
                <Button onClick={onCopyText}>Copy</Button>
            </ButtonWithTooltip>
            <Button onClick={onMakeMore}>Make more</Button>
        </ButtonGroup>
    </StyledIpsumPage>
);

IpsumPage.propTypes = {
    children: PropTypes.string.isRequired,
    regenerate: PropTypes.func,
    mode: PropTypes.string,
    modeChoices: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChangeMode: PropTypes.func,
    onCopyText: PropTypes.func,
    copied: PropTypes.bool,
    onMakeMore: PropTypes.func,
};

IpsumPage.defaultProps = {
    regenerate: () => {},
    mode: null,
    onChangeMode: () => {},
    onCopyText: () => {},
    copied: false,
    onMakeMore: () => {},
};

export default IpsumPage;
