export default {
    genericSubjects: [
        'they',
        'it',
        'someone',
    ],

    subject: [
        'they',
        'it',
        'someone',
        'Satoshi Nakamoto',
        'blockchain',
        'Bitcoin',
        'Ethereum',
        'ERC20 token standard',
        'ERC721 token standard',
        'SHA 256',
        'Solidity',
        'ether',
        'Ripple',
        'Litecoin',
        'EOS',
        'Bitcoin Cash',
        'Binance Coin',
        'Tether',
        'Stellar',
        'Cardano',
        'TRON',
        'Monero',
        'IOTA',
        'Dash',
        'Maker',
        'NEO',
        'Ontology',
        'Tezos',
        'VeChain',
        'Zcash',
        'Waves',
        'Basic Attention Token',
        'Dogecoin',
        'OmiseGo',
        'Decred',
        'Ravencoin',
        'Augur',
        'NFT',
        'Zilliqa',
        'Golem',
        'ICO',
        'IPO',
        'Digitex Futures',
        'Nexo',
        'Gwei',
        'Lightning Network',
        'Mt. Gox',
        'Silk Road',
    ],
    verb: [
        'counted',
        'did',
        'was',
        'looked at',
        'thought',
        'is',
        'was',
        'could be',
        'should be',
        'data mining',
        'halving',
        'mining',
        'thinking',
        'thought',
        'bought',
        'surrendered',
        'expected',
        'accompanied by',
        'allowed',
        'based on',
        'is wary of',
        'broadcast',
        'built',
        'chose',
        'controls',
        'cooperated',
        'cost',
        'cut off',
        'detected',
        'forgot',
        'formed',
        'froze',
        'generates',
        'generated',
        'identified',
        'launched',
        'left',
        'limited',
        'managed',
        'proves',
        'rejoins',
        'required',
        'returns',
        'serves',
        'sharded',
        'slept on',
        'specialises in',
        'stacks',
        'stuck',
        'threw away',
        'waited',
    ],
    noun: [
        'crypto',
        'cryptocurrency',
        'blockchain',
        'block',
        'chain',
        'hash',
        'hyperledger',
        'node',
        'oracle',
        'peer-to-peer network',
        'permissioned ledger',
        'private chain',
        'private key',
        'public key',
        'proof of stake',
        'proof of work',
        'smart contract',
        'token',
        'gas',
        '51% attack',
        'accidental fork',
        'address',
        'agreement ledger',
        'altcoin',
        'block height',
        'block reward',
        'central ledger',
        'coin',
        'cold wallet',
        'confirmation',
        'consensus process',
        'consensus point',
        'decentralised autonomous organisation',
        'decentralisation',
        'decentralised application',
        'dapp',
        'digital identity',
        'digital signature',
        'distributed ledger',
        'difficulty',
        'double spend',
        'ERC20 token standard',
        'ERC721 token standard',
        'exchange',
        'fiat',
        'fork',
        'genesis block',
        'hard fork',
        'hardware wallet',
        'hashrate',
        'hot wallet',
        'initial coin offering',
        'ledger',
        'multi signature',
        'non-fungible token',
        'off-ledger currency',
        'on-ledger currency',
        'proof of authority',
        'protocol',
        'SHA 256',
        'soft fork',
        'stablecoin',
        'transaction fee',
        'whitepaper',
        'ICO',
        'IPO',
        'airdrop',
        'algorithm',
        'all-time-high',
        'all-time-low',
        'anarcho-capitalism',
        'anti-money laundering',
        'arbitrage',
        'ashdraked',
        'astroturfing',
        'atomic swap',
        'attestation ledger',
        'bag',
        'bagholder',
        'bear',
        'bear trap',
        'bollinger band',
        'bubble',
        'bug bounty',
        'burned',
        'circulating supply',
        'crypto-jacking',
        'custodial',
        'dead cat bounce',
        'delegated proof-of-stake',
        'deterministic wallet',
        'directed acyclic graph',
        'distributed denial of service attack',
        'do your own research',
        'dolphin',
        'dump',
        'dust transaction',
        'escrow',
        'faucet',
        'fish',
        'flippening',
        'FOMO',
        'FUD',
        'fundamental analysis',
        'instamine',
        'Lambo',
        'mainnet',
        'market cap',
        'max supply',
        'mnemonic phrase',
        'moon',
        'nonce',
        'orphan',
        'over the counter',
        'pre-mine',
        'pre-sale',
        'price',
        'pump and dump',
        'raiden network',
        'REKT',
        'segregated witness',
        'shilling',
        'shitcoin',
        'side chain',
        'stale block',
        'taint',
        'technical analysis',
        'testnet',
        'token generation event',
        'trustless',
        'turing-complete',
        'unconfirmed',
        'unspent transaction output',
        'validator',
        'vanity address',
        'vaporware',
        'volume',
        'wash trade',
        'whale',
        'zero confirmation transaction',
        'zero knowledge proof',
    ],
    adjective: [
        'immutable',
        'provably',
        'provably fair',
        'peer-to-peer',
        'algo-traded',
        'amazing',
        'automated',
        'burned',
        'centralised',
        'considerable',
        'constant',
        'dormant',
        'efficient',
        'hot',
        'instant',
        'lightning fast',
        'minimum',
        'quick',
        'reinvested',
        'robust',
        'safe',
        'trusted',
    ],
    preposition: [
        'in',
        'at',
        'for',
        'behind',
        'until',
        'after',
        'of',
        'during',
    ],
    article: [
        'a',
        'the',
        'some',
        'many',
        'lots of',
        'few',
    ],
    punctuation: [
        ',',
    ],
    coordConjunct: [
        'for',
        'and',
        'nor',
        'but',
        'or',
        'yet',
        'so',
        'however,',
        'therefore,',
    ],
    qualifier: [
        'because',
        'since',
        'although',
        'when',
    ],
    stop: [
        '.',
        '!',
    ],
};
