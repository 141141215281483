import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import Choice from "../Choice";


const StyledChoices = styled.div`
    display: flex;
    
    justify-content: center;
    align-items: center;
    
    width: 100%;
    height: 40px;
    
    margin-bottom: 40px;
    
    @media (max-width: ${props => props.theme.bps.md}) {
      margin-bottom: 20px;
    }
`;


const Choices = ({ choices, chosen, onChoose }) => (
    <StyledChoices>
        {choices.map(choice =>
            <Choice
                key={choice}
                selected={choice === chosen}
                onClick={() => {
                    onChoose(choice);
                }}
            >
                {choice}
            </Choice>
        )}
    </StyledChoices>
);

Choices.propTypes = {
    choices: PropTypes.arrayOf(PropTypes.string).isRequired,
    chosen: PropTypes.string,
    onChoose: PropTypes.func,
};

Choices.defaultProps = {
    chosen: null,
    onChoose: () => {}
};

export default Choices;
