import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IpsumPage from "./IpsumPage";
import styled from "styled-components";
import { addRandomHtml, addRandomMarkdown } from "./util/transformers";


const ClipboardTarget = styled.textarea`
  width: 1px;
  height: 1px;
  position: fixed;
  left: -1px;
  top: -1px;
  opacity: 0;
`;


class Container extends Component {
    transformFuncs = {
        'plain text': text => text,
        'markdown': addRandomMarkdown,
        'html': addRandomHtml,
    };

    copyTarget = React.createRef();

    copyText = () => {
        this.copyTarget.current.focus();
        document.execCommand('selectAll');
        document.execCommand('copy');

        this.setState({
            copied: true,
        });

        this.timeout = setTimeout(
            () => {
                this.setState({
                    copied: false,
                });
            },
            2000,
        );
    };

    state = {
        copied: false,
    };

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    render() {
        const { rawText, regenerate, mode, modeChoices, onChangeMode, onMakeMore } = this.props;
        const { copied } = this.state;

        const text = this.transformFuncs[mode](rawText);

        return (
            <>
                <IpsumPage
                    regenerate={regenerate}
                    mode={mode}
                    modeChoices={modeChoices}
                    onChangeMode={onChangeMode}
                    onCopyText={this.copyText}
                    copied={copied}
                    onMakeMore={onMakeMore}
                >
                    {text}
                </IpsumPage>
                <ClipboardTarget
                    value={text}
                    readOnly
                    ref={this.copyTarget}
                />
            </>
        );
    }
}

Container.propTypes = {
    rawText: PropTypes.string.isRequired,
    regenerate: PropTypes.func,
    mode: PropTypes.string.isRequired,
    modeChoices: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChangeMode: PropTypes.func,
    onMakeMore: PropTypes.func,
};

Container.defaultProps = {
    regenerate: () => {},
    onChangeMode: () => {},
    onMakeMore: () => {},
};

export default Container;
