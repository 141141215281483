import seedrandom from 'seedrandom';

import sentenceParts from './sentenceParts';


class Generator {
    constructor(seed) {
        this._seed = seed;
        this.random = seedrandom(seed);
    }

    choice = (options) => options[Math.floor(this.random() * options.length)];

    capitalise = (s) => `${s.charAt(0).toUpperCase()}${s.slice(1)}`;

    // from lists
    subject = () => this.choice(sentenceParts.subject);
    verb = () => this.choice(sentenceParts.verb);
    noun = () => this.choice(sentenceParts.noun);
    adjective = () => this.choice(sentenceParts.adjective);
    preposition = () => this.choice(sentenceParts.preposition);
    article = () => this.choice(sentenceParts.article);
    punctuation = () => this.choice(sentenceParts.punctuation);
    coordConjunct = () => this.choice(sentenceParts.coordConjunct);
    qualifier = () => this.choice(sentenceParts.qualifier);
    stop = () => this.choice(sentenceParts.stop);

    object = () => this.choice([
        `${this.article()} ${this.noun()}`,
        `${this.article()} ${this.adjective()} ${this.noun()}`,
    ]);
    prepositionalPhrase = () => `${this.preposition()} ${this.article()} ${this.noun()}`;

    simple = () => this.choice([
        `${this.subject()} ${this.verb()} ${this.object()}`,
        `${this.subject()} ${this.verb()} ${this.object()} ${this.prepositionalPhrase()}`,
    ]);

    independentClause = () => `${this.simple()}`;
    dependentClause = () => `${this.qualifier()} ${this.simple()}`;

    complex = () => this.choice([
        `${this.dependentClause()}${this.punctuation()} ${this.independentClause()}`,
        `${this.independentClause()} ${this.dependentClause()}`,
    ]);

    simpleOrComplex = () => this.choice([
        `${this.simple()}`,
        `${this.complex()}`,
    ]);

    compound = () => `${this.simpleOrComplex()}${this.punctuation()} ${this.coordConjunct()} ${this.simpleOrComplex()}`;

    sentence = () => this.capitalise(this.choice([
        `${this.simpleOrComplex()}${this.stop()}`,
        `${this.compound()}${this.stop()}`,
    ]));

    paragraph = () => {
        let paragraph = '';

        for (let i=0; i < 3 + Math.floor(this.random() * 4); i++) {
            paragraph += this.sentence();
            paragraph += ' ';
        }

        return paragraph.slice(0, -1);
    };

    orphan = () => {
        let subject;
        do {
            subject = this.subject();
        } while (sentenceParts.genericSubjects.includes(subject) || subject.includes(' '));

        return this.capitalise(`${subject}${this.stop()}`);
    };
}


export default ({ num, unit }) => {
    const g = new Generator();

    let text = '';

    switch (unit) {
        case 'paragraphs':
            for (let i=0; i < num; i++) {
                text += g.paragraph();
                text += '\n\n';
            }

            text = text.slice(0, -2);

            break;
        case 'words':
            if (num === 1) {
                // ensure we don't have an orphan qualifier if only one word is given
                text = g.orphan();
                break;
            }

            let wordCount = 0;
            let atLeastOnePara = false;
            while (true) {
                const nextPara = g.paragraph();

                wordCount += nextPara.split(' ').length;

                if (wordCount >= num) {
                    text += ' ';
                    text += nextPara;
                    break;
                }

                atLeastOnePara = true;

                text += '\n\n';
                text += nextPara;
            }

            text = text.slice(atLeastOnePara ? 2 : 1);

            text = text.split(' ').slice(0, num).join(' ');

            if (!sentenceParts.stop.includes(text.slice(-1))) {
                text += g.stop();
            } else if (sentenceParts.punctuation.includes(text.slice(-1))) {
                text = text.slice(0, -1);
                text += g.stop();
            }

            break;
        default:
            throw new Error(`Unrecognised unit ${unit}`);
    }

    return text;
};
