import React from 'react';


export default () =>
    <svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z"
        />
        <path
            d="M24.3821 47.1722C21.4635 40.6116 22.8194 33.1253 27.8334 28.101C32.8498 23.0813 40.3322 21.7239 46.8908 24.6443C46.953 24.6716 47.0166 24.6829 47.0788 24.7065L43.8669 27.2307C42.5255 28.2838 42.2906 30.2231 43.3432 31.5668C43.9518 32.3423 44.8575 32.7497 45.7722 32.7497C46.4414 32.7497 47.1106 32.5342 47.676 32.0911L55.8185 25.6966C56.7424 24.972 57.1744 23.7861 56.9349 22.6367L54.8151 12.4584C54.4688 10.79 52.8378 9.7179 51.1667 10.0654C49.4987 10.4129 48.427 12.0487 48.7749 13.7194L49.7533 18.4212C49.7268 18.4075 49.7048 18.3893 49.676 18.3772C40.5058 14.293 30.032 16.2042 22.9861 23.2505C15.9493 30.3005 14.0394 40.7861 18.1206 49.9643C18.6882 51.2413 19.9402 52 21.2536 52C21.7189 52 22.1926 51.9036 22.6458 51.7026C24.3753 50.931 25.1506 48.9029 24.3821 47.1722Z"
            fill="#FFFCFC"
        />
        <path
            d="M61.9047 30.0274C61.1455 28.3047 59.1394 27.5298 57.4267 28.2949C55.714 29.0585 54.9428 31.0755 55.705 32.7974C58.5888 39.329 57.2482 46.7743 52.2834 51.7658C47.4206 56.6569 40.2194 58.0446 33.8278 55.3681L37.0018 52.8652C38.3274 51.8179 38.5615 49.8869 37.518 48.5506C36.4775 47.2173 34.5577 46.9818 33.2313 48.0314L25.1697 54.391C24.256 55.1116 23.8269 56.291 24.0647 57.4326L26.1622 67.5537C26.4623 69.004 27.7316 70 29.148 70C29.3536 70 29.5644 69.9789 29.7729 69.9366C31.4234 69.5888 32.4849 67.9634 32.1413 66.3018L31.1773 61.6484C34.1954 62.9199 37.3424 63.5477 40.455 63.5477C46.5759 63.5477 52.5497 61.1496 57.0847 56.586C64.0472 49.5874 65.94 39.1592 61.9047 30.0274Z"
            fill="#FFFCFC"
        />
    </svg>
;
